<template>
  <div  class="px-15px px-lg-25px">
    
    <div class="card">
      <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('chan-pin-ping-lun') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0" :class="tableData.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('chan-pin') }}</th>
              <th data-breakpoints="lg">{{ $t('ke-hu') }}</th>
              <th>{{ $t('ping-fen') }}</th>
              <th data-breakpoints="lg">{{ $t('ping-lun') }}</th>
              <th data-breakpoints="lg">{{ $t('zhuang-tai') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>
                  {{ i + 1 }}
              </td>
              <td>
                <a>{{  item.name }}</a>
              </td>
              <td>{{item.userNmae}}</td>
              <td>
                <span class="rating rating-sm">
                  <i class="las la-star" v-for="n in 5" :key="n" :class="n <= item.star ? 'active' : ''" ></i>
                </span>
              </td>
              <td>{{item.review}}</td>
              <td>
                <span class="badge badge-inline badge-success" v-if="item.status == 1">{{ $t('yi-fa-bu') }}</span>
                <span class="badge badge-inline badge-danger" v-else>{{ $t('wei-fa-bu') }}</span>
              </td>
            </tr>
            <tr class="footable-empty" v-if="tableData.length == 0"><td colspan="6">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination mt-2" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page"></pageBar>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
export default {
  components: {
    pageBar
  },
  data() {
    return {
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      tableData: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      }
    }
  }
}
</script>